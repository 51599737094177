const Path = {
  Slash: "/",
  Auth: "/auth",
  ForgotPassword: "/auth/forgot",
  ResetPassword: "/auth/reset",
  Download: "/download",
  Payment: "/payment",
  ValidatePayment: "/payment/validate",
  PaymentHistory1: "/payment/history",
  PaymentHistory: "/payment-history",
  PaymentHistoryBusiness: "/payment-history/business",
  PaymentHistoryConsumer: "/payment-history/consumer",
  GenerateQr: "/generate-qr/",
  GenerateQrPreview: "/generate-qr/preview",
  ActivateAccount: "/activate-account",
  SelectStation: "/select-station",
  SettlementHistory: "/settlement/history",
  Inbox: "/inbox",
  ViewSettlementHistoryById: (id) => `/settlement/history/${id}/view`,
  ViewSettlementHistory: "/settlement/history/:id/view",
  PayWithPeso: "/payment-history/pay-with-peso",
  NonFuel: "/payment-history/non-fuel",
  PremiumPayout: "/premium-payout",
  ViewPremiumPayoutById: (id) => `/premium-payout/${id}/view`,
  ViewPremiumPayout: "/premium-payout/:id/view",
  AccessAuthorization: "/access-authorization",
  UserAccess: "/access-authorization/user-access",
  OtherServices: `/other-services`,
  LubeServOnSiteBookings: `/other-services/lubeserv-on-site-bookings`,
  PayWithLOCQPay: "/payment-history/pay-with-locqpay",
  VIPAccount: "/vip",
  VIPTransactionHistory: "/vip/transaction-history",
  VIPPoints: "/vip/points",
  VIPHistoryAwardPoints: "/vip/transaction-history/award-points",
  VIPHistoryPayWithPoints: "/vip/transaction-history/pay-with-points",
  VIPHistoryRedeemPoints: "/vip/transaction-history/redeem-points",
  VIPAwardPoints: "/vip/points/award-points",
  VIPPayWithPoints: "/vip/points/pay-with-points",
  VIPRegistration: "/vip/register",
  LOCQPayNonFuel: "/payment-history/plb-locqpay-non-fuel",
  VIPRedemption: "/vip/points/redemption",
  CreditAccounts: "/credit-accounts",
};

export default Path;
