import { FleetStatus, BilledStatus } from "enums";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  page: 1,
  perPage: 10,
  fleetStatus: [FleetStatus.Active, FleetStatus.InActive],
  status: [BilledStatus.Billed],
  order: "asc",
});
