const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  GetUsers: "ms-profile/user/users",
  GetUserStationInvite: "ms-fleet/station",
  UpdateUserAccess: "ms-profile/user/user-access",
  SendResetPassword: "ms-fleet/user/reset-password",
  ChangePassword: "ms-profile/user/password/change",
  ValidateToken: "ms-profile/user/validate/token",
  GetUserStation: "ms-fleet/user/station",
  FuelCode: "ms-fleet/fuel-code",
  User: "ms-fleet/user",
  Redemption: "ms-fleet/redemption",
  Station: "ms-fleet/station",
  FuelBalance: "ms-fleet/fuel-balance/fleet",
  FleetRedemption: "ms-fleet/redemption",
  SettlementHistory: "ms-pricing/settlement/dealer",
  SettlementRedemption: "ms-pricing/redemption/settlement",
  GeneratePricingReport: "ms-pricing/report",
  GenerateStationPricingReport: "ms-pricing/report/station",
  GenerateStationReport: "ms-fleet/report/station/v3",
  GenerateStationReportLoyalty: "ms-loyalty/report/station",
  Inbox: "ms-inbox/message/user",
  StationPayments: (stationId) => `ms-fleet/payment/station/${stationId}`,
  MerchantStationPayments: (stationId) => `ms-fleet/merchant/payment/station/${stationId}`,
  GenerateReportAdmin: "ms-fleet/report/admin/v3",
  UpdateReport: (id) => `ms-report/report/${id}`,
  GenerateReportList: "ms-report/report",
  PremiumPayout: "ms-pricing/premium-payout",
  GetBooking: "ms-booking/booking",
  GenerateBookingReport: "ms-booking/report/station",
  FleetCard: "ms-fleet/fuel-card",
  LoyaltyCard: "ms-loyalty",
  UpdatePayWithPoints: (id) => `ms-loyalty/loyalty/history/${id}`,
  NonFuelTransactions: "ms-fleet/merchant/payment/station",
  GetCreditAccounts: "ms-fleet/billing/station",
};

export default ApiPath;
