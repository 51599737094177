import { Path } from "enums";
import CreditAccountsModule from "modules/credit-accounts/credit-accounts.module";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const CreditAccountsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.CreditAccounts} component={CreditAccountsModule} />
      <Redirect to={Path.CreditAccounts} />
    </Switch>
  );
};

export default CreditAccountsPage;
