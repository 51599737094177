import { FuelIcon, Title, Text, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import { formatNumber, formatAmount, formatVolume, parseLiter } from "utils";
import styles from "./validate-payment-confirm-page.module.scss";
import { prettifyProduct, prettifyDispenseType } from "utils/pretty.utils";
import classNames from "classnames";
import { PaymentMethod, PLBTypes, InvoicingSetup } from "enums";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

const ValidatePaymentConfirmPage = ({
  loading,
  dispensedTo,
  literVolume,
  details,
  confirm,
  handleBackProceedToPayment,
  payment,
  productType,
  fields,
  checkCompanyGroup,
}) => {
  const discount = fields?.totalDiscount.value;
  // const subTotal = useMemo(() => {
  //   return (fields?.pumpPrice?.value || 0) * formatNumber(fields?.computedVolume?.value || 0);
  // }, [fields?.pumpPrice?.value, fields?.computedVolume?.value]);
  const subTotal = payment;
  return (
    <div className={styles.confirmContainer}>
      <div className={styles.confirmation}>
        <Text className={styles.headerValidateTitle} strong>
          {locale.proceedwithPaymentQuestion}
        </Text>
        <Title medium className={styles.title}>
          {details.paymentMethod === PaymentMethod.FleetCard ||
          details.paymentMethod === PaymentMethod.FleetCardManualPO ||
          details.paymentMethod === PaymentMethod.PLBLocqpayManualPOCredit ? (
            <>{formatAmount(parseFloat(parseLiter(subTotal)) - discount)}</>
          ) : (
            <locale.Populate
              text={literVolume === 1 ? locale.nLiter : locale.nLiters}
              items={[formatNumber(literVolume)]}
            />
          )}
        </Title>
        <Text>
          <locale.Populate
            text={locale.ofText}
            className={styles.fuel}
            items={[
              <span className={styles.fuel}>
                <FuelIcon
                  className={styles.icon}
                  type={
                    details.paymentMethod === PaymentMethod.FleetCard ||
                    details.paymentMethod === PaymentMethod.FleetCardManualPO ||
                    details.paymentMethod === PaymentMethod.PLBLocqpayManualPOCredit
                      ? productType
                      : details.productCode
                  }
                />
                <span className={styles.productFuel}>
                  {prettifyProduct(
                    details.paymentMethod === PaymentMethod.FleetCard ||
                      details.paymentMethod === PaymentMethod.FleetCardManualPO ||
                      details.paymentMethod === PaymentMethod.PLBLocqpayManualPOCredit
                      ? productType
                      : details.productCode
                  )}
                </span>
              </span>,
            ]}
          />
        </Text>
        <Text style={{ textAlign: "center" }}>
          {locale.isAboutToBeRedeemed} {locale.kindlyReviewTheDetails}
        </Text>
        {details.paymentMethod === PaymentMethod.FleetCard ||
        details.paymentMethod === PaymentMethod.FleetCardManualPO ||
        details.paymentMethod === PaymentMethod.PLBLocqpayManualPOCredit ? (
          <div className={styles.detailsContainer}>
            <div className={styles.contentPayment}>
              <div className={styles.tableContainer}>
                {productType === locale.lubes ? (
                  <div className={styles.table}>
                    <div className={styles.tableGasDetail}>
                      <div className={styles.row}>
                        <label>{locale.totalAmount}</label>
                        <label>{formatAmount(parseFloat(parseLiter(subTotal)) || 0)}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.remarks}</label>
                        <label>{fields?.remarks?.value}</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.table}>
                    <div className={styles.tableGasDetail}>
                      <div className={styles.row}>
                        <label>{locale.pumpPrice}</label>
                        <label>{formatAmount(fields?.pumpPrice?.value || 0)}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.computedVolume}</label>
                        <label>{formatVolume(fields?.computedVolume?.value || 0)}</label>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.horizontalLine}></div>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.subTotal}</label>
                        <label>{formatAmount(parseFloat(parseLiter(subTotal)))}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.totalDiscount}</label>
                        <label>
                          {fields?.totalDiscount.value > 0 && <>-</>}{" "}
                          {formatAmount(fields?.totalDiscount.value || 0)} <br />
                          <span style={{ color: "#a1aeb7", fontSize: "12px" }}>{`(${formatAmount(
                            fields?.discountPerLiter.value || 0
                          )} / L)`}</span>
                        </label>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.horizontalLine}></div>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.totalAmount}</label>
                        <label style={{ color: "#0054a7" }}>
                          {formatAmount(parseFloat(parseLiter(subTotal)) - discount)}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.contentDetails}>
              <div className={styles.tableContainer}>
                {productType === locale.lubes ? (
                  <>
                    <div className={styles.table}>
                      <div className={styles.row}>
                        <label>{locale.businessName}</label>
                        <label>{details.fleet?.businessName || ""}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.fleetCardNo}</label>
                        <label>{details.fleetCardNo}</label>
                      </div>

                      <div className={styles.row}>
                        <label>{locale.eligibleDriver}</label>
                        <label>{details?.driverName || locale.any}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.driverId}</label>
                        <label>{details?.driverLicenseId || locale.any}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.eligibleVehicle}</label>
                        <label
                          className={classNames({
                            italic: !details.vehiclePlateNumber,
                          })}
                        >
                          {details.vehiclePlateNumber || locale.any}
                        </label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.vehicleModel}</label>
                        <label
                          className={classNames({
                            italic: !details.vehicleModel,
                          })}
                        >
                          {details.vehicleModel || locale.any}
                        </label>
                      </div>

                      <div className={styles.row}>
                        <label>{locale.fleetCardRemarks}</label>
                        <label
                          className={classNames(styles.remarks, {
                            italic: !details.vehicle?.remarks,
                          })}
                        >
                          {details?.vehicle?.remarks || locale.any}
                        </label>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.table}>
                      <div className={styles.row}>
                        <label>{locale.businessName}</label>
                        <label>{details.fleet?.businessName || ""}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.fleetCardNo}</label>
                        <label>{details.fleetCardNo}</label>
                      </div>
                      {details?.fleet?.plbType &&
                        (details?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO ||
                          details?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO_CREDIT) && (
                          <div className={styles.row}>
                            <label>{locale.purchaseOrderNo}</label>
                            <label>
                              {details.purchaseOrderNo || fields?.purchaseOrderNo?.value}
                            </label>
                          </div>
                        )}

                      <div className={styles.row}>
                        <label>{locale.eligibleDriver}</label>
                        <label>{details?.driverName || locale.any}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.driverId}</label>
                        <label>{details?.driverLicenseId || locale.any}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.eligibleVehicle}</label>
                        <label
                          className={classNames({
                            italic: !details.vehiclePlateNumber,
                          })}
                        >
                          {details.vehiclePlateNumber || locale.any}
                        </label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.vehicleModel}</label>
                        <label
                          className={classNames({
                            italic: !details.vehicleModel,
                          })}
                        >
                          {details.vehicleModel || locale.any}
                        </label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.dispensedTo}</label>
                        <label>{prettifyDispenseType(dispensedTo)}</label>
                      </div>
                      <div className={styles.row}>
                        <label>{locale.remarks}</label>
                        <label
                          className={classNames(styles.remarks, {
                            italic: !details.vehicle?.remarks,
                          })}
                        >
                          {details?.vehicle?.remarks || locale.any}
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.tableContainer}>
            <div className={styles.table}>
              <div className={styles.row}>
                <label>{locale.businessName}</label>
                <label>{details.fleet?.businessName || ""}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.fuelCode}</label>
                <label>{details.fuelCode}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleDriver}</label>
                <label>{details?.driverName || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.driverId}</label>
                <label>{details?.driverLicenseId || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleVehicle}</label>
                <label
                  className={classNames({
                    italic: !details.vehiclePlateNumber,
                  })}
                >
                  {details.vehiclePlateNumber || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.vehicleModel}</label>
                <label
                  className={classNames({
                    italic: !details.vehicleModel,
                  })}
                >
                  {details.vehicleModel || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.dispensedTo}</label>
                <label>{prettifyDispenseType(dispensedTo)}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.remarks}</label>
                <label className={classNames(styles.remarks, { italic: !details.remarks })}>
                  {details.remarks || locale.any}
                </label>
              </div>
            </div>
          </div>
        )}
        {details?.fleet?.invoicingSetup === InvoicingSetup.SingleInvoicing && checkCompanyGroup && (
          <div className={styles.noteDoNotIssueReceiptSection}>
            <div className={styles.noteDoNotIssueReceipt} style={{ width: "60%" }}>
              <ErrorOutline className={styles.warningSign} />
              <div className={styles.warningTextSection}>
                <span className={styles.warningTextSingleInvoice}>
                  {locale.plbAccountIsInSingleInvoicing}
                </span>
                <span className={styles.warningTextDoNotIssue}>{locale.doNotIssueReceipt}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <ActionButton
        center
        secondaryIcon={<span className="icon-back" />}
        items={[
          {
            text: locale.back,
            onClick: () => {
              handleBackProceedToPayment();
            },
          },
          {
            text: locale.proceed,
            onClick: () => confirm(),
            loading,
          },
        ]}
      />
    </div>
  );
};

export default ValidatePaymentConfirmPage;
