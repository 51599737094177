import React from "react";
import styles from "./credit-accounts-filter.module.scss";
import { prettifyFleetStatus } from "utils/pretty.utils";
import { Field, SearchField, Filter, GroupCheckbox, Button } from "components/commons";
import locale from "localization";
import { FleetStatus } from "enums";

const CreditAccountsFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  exportReport,
  exporting,
}) => {
  const { fleetStatus } = filterState;
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <div className="flex items-center">
          <Filter submit={applyFilter} close={resetFilter} clear={applyClearFilter}>
            {/* <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.dueDateRange}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field> */}
            {/* <GroupCheckbox
              className={styles.status}
              title={locale.billingStatement}
              name={"status"}
              options={[
                {
                  content: (
                    <div className="flex items-center">
                      {prettifyBilledStatus(BilledStatus.Paid)}
                    </div>
                  ),
                  value: BilledStatus.Paid,
                },
                {
                  content: (
                    <div className="flex items-center">
                      {prettifyBilledStatus(BilledStatus.Billed)}
                    </div>
                  ),
                  value: BilledStatus.Billed,
                },
              ]}
              value={status}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value });
              }}
            /> */}
            <GroupCheckbox
              className={styles.status}
              title={locale.status}
              name={"fleetStatus"}
              options={[
                {
                  content: (
                    <div className="flex items-center">
                      {prettifyFleetStatus(FleetStatus.Active)}
                    </div>
                  ),
                  value: FleetStatus.Active,
                },
                {
                  content: (
                    <div className="flex items-center">
                      {prettifyFleetStatus(FleetStatus.InActive)}
                    </div>
                  ),
                  value: FleetStatus.InActive,
                },
              ]}
              value={fleetStatus}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value });
              }}
            />
          </Filter>
          <Field className={styles.search}>
            <SearchField
              maxLength={75}
              restrict={false}
              searchWithSymbols
              placeholder={locale.creditAmountSearch}
              value={searchKey}
              onChange={(_, { value }) => {
                modifySearch(value);
              }}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default CreditAccountsFilter;
