import {
  Products,
  DispenseType,
  ReportType,
  SettlementStatus,
  Role,
  VoidPaymentValue,
  OilChangeType,
  LubeServSite,
  PaymentMethod,
  ConsumerType,
  VehicleType,
  PaymentStatus,
  VoidPaymentNonFuelValue,
  FleetStatus,
  BilledStatus,
} from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import RedemptionStatus from "enums/redemption-status";
import { VoidTransactionValue } from "enums/void-transaction-value";
import { UserStatus } from "enums";
import locale from "localization";
import VipType from "enums/vip-type";

export const prettifyVehicleType = (vehicleType) => {
  return (
    {
      [VehicleType.Sedan]: locale.sedan,
      [VehicleType.Suv]: locale.suv,
      [VehicleType.Vanmpv]: locale.vanmpv,
      [VehicleType.Hatchback]: locale.hatchback,
      [VehicleType.Motorcycle]: locale.motorcycle,
      [VehicleType.Tricycle]: locale.tricycle,
      [VehicleType.Truck]: locale.truck,
      [VehicleType.Jeepney]: locale.jeepney,
      [VehicleType.Bus]: locale.bus,
      [VehicleType.Others]: locale.others,
    }[vehicleType] || ""
  );
};

export const prettifyConsumerType = (consumerType) => {
  return (
    {
      [ConsumerType.CommercialIndustrial]: locale.commercialIndustrial,
      [ConsumerType.Private]: locale.private,
      [ConsumerType.Public]: locale.public,
      [ConsumerType.TNVS]: locale.tnvs,
    }[consumerType] || ""
  );
};

export const prettifyProduct = (productCode) => {
  return (
    {
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Products.Diesel]: locale.diesel,
      [Products.Lubes]: locale.lubricants,
      [Products.Lubricants]: locale.lubricants,
      [Products.Lubserv]: locale.lubeserv,
      [Products.Seagas]: locale.seaGas,
    }[productCode] || ""
  );
};
export const prettifyDispenseType = (dispenseType) => {
  return (
    {
      [DispenseType.Vehicle]: locale.vehicle,
      [DispenseType.DrumContainer]: locale.drumContainer,
    }[dispenseType] || ""
  );
};

export const prettifyRedemptionStatus = (redemptionStatus) => {
  return (
    {
      [RedemptionStatus.Success]: locale.success,
      [RedemptionStatus.Voided]: locale.voided,
    }[redemptionStatus] || ""
  );
};

export const prettifySettlementStatus = (type) => {
  return (
    {
      [SettlementStatus.ForProcessing]: locale.forProcessing,
      [SettlementStatus.ForSettlement]: locale.forSettlement,
      [SettlementStatus.Processed]: locale.processed,
      [SettlementStatus.Cancelled]: locale.cancelled,
    }[type] || ""
  );
};

export const prettifyPayWithPesoStatus = (type) => {
  return (
    {
      [PayWithPesoStatus.Pending]: "Pending",
      [PayWithPesoStatus.Success]: "Success",
      [PayWithPesoStatus.Failed]: "Failed",
      [PayWithPesoStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyPayWithLOCQPayStatus = (type) => {
  return (
    {
      [PayWithPesoStatus.Pending]: "Pending",
      [PayWithPesoStatus.Success]: "Success",
      [PayWithPesoStatus.Failed]: "Failed",
      [PayWithPesoStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyReportType = (type) => {
  return (
    {
      [ReportType.CashIn]: "Cashin",
      [ReportType.BuyFuel]: "Buy Fuel",
      [ReportType.Redemption]: "Redemption",
      [ReportType.RedemptionItem]: "Redemption Item",
      [ReportType.PricingHistory]: "Pricing History",
      [ReportType.FuelCode]: "Fuel Code",
      [ReportType.FuelCodes]: "Fuel Codes",
      [ReportType.Mops]: "Mops",
      [ReportType.VoucherClaim]: "Voucher Claim",
      [ReportType.NewCustomerVoucher]: "New Customer Voucher",
      [ReportType.FleetPrices]: "Fleet Prices",
      [ReportType.PriceBuildup]: "Price Buildup",
      [ReportType.FleetAccount]: "Fleet Account",
      [ReportType.StationAccount]: "Station Account",
      [ReportType.CustomerList]: "Customer List",
      [ReportType.VoucherCampaign]: "Voucher Campaign",
      [ReportType.DiscountCampaign]: "Discount Campaign",
      [ReportType.RfidTopup]: "Rfid Topup",
      [ReportType.Payment]: "Payment",
      [ReportType.MerchantPayment]: "Merchant Payment",
      [ReportType.KYCSubmission]: "KYC Submission",
      [ReportType.PriceMovement]: "Price Movement",
      [ReportType.SupplierWPP]: "Supplier WPP",
      [ReportType.BusinessRedemption]: "Business Redemption",
      [ReportType.ConsumerRedemption]: "Consumer Redemption",
      [ReportType.LubeservOnSite]: "LubeServ on Site",
      [ReportType.VIPAwardPoints]: "VIP Award Points",
      [ReportType.VIPRedeemPoints]: "VIP Redeem Points",
      [ReportType.VIPPayWithPoints]: "VIP Pay With Points",
      [ReportType.Billing]: "Credit Accounts",
    }[type] || ""
  );
};

export const prettifyVoidTransactionValue = (value) => {
  return (
    {
      [VoidTransactionValue.WrongInputByUser]: locale.mischargeWrongInputByUser,
      [VoidTransactionValue.WrongVolumeInput]: locale.mischargeWrongVolumeInput,
      [VoidTransactionValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyRole = (role) => {
  return (
    {
      [Role.PortalAdmin]: locale.admin,
      [Role.Accounting]: locale.accounting,
      [Role.StationCashier]: locale.cashier,
      [Role.LubeServ]: locale.lubeserv,
    }[role] || ""
  );
};

export const prettifyVoidPaymentValue = (value) => {
  return (
    {
      [VoidPaymentValue.WrongAmountInput]: locale.michargedWrongAmountInput,
      [VoidPaymentValue.DidNotIntendToPayViaPeso]: locale.michargedDidNotIntendtoPayViaLocqpay,
      [VoidPaymentValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyVoidPaymentNonFuelValue = (value) => {
  return (
    {
      [VoidPaymentNonFuelValue.CustomerPaidExcessAmount]: locale.michargedCustomerPaidExcessAmount,
      [VoidPaymentNonFuelValue.CustomerPaidWrongProduct]: locale.michargedCustomerPaidWrongProduct,
      [VoidPaymentNonFuelValue.CustomerWasDoubleCharged]: locale.michargedCustomerWasDoubleCharged,
      [VoidPaymentNonFuelValue.CustomerPaidWronngAmountInput]:
        locale.mischargeCustomerWrongAmountInput,
      [VoidPaymentNonFuelValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyOilChangeType = (type) => {
  return (
    {
      [OilChangeType.dieselMonoGrade]: "Diesel Engine Oil Monograde Mineral (40)",
      [OilChangeType.dieselMultiGrade]: "Deomax Multigrade Mineral (15W-40)",
      [OilChangeType.dieselFullySynthetic]: "Extreme Mako Fully Synthetic (5W-40)",
      [OilChangeType.gasolineMonoGrade]: "Gasoline Engine Oil Monograde Mineral (40)",
      [OilChangeType.gasolineMultiGrade]: "Geo Supreme Multigrade Mineral (20W-50)",
      [OilChangeType.gasolineFullySynthetic]: "Extreme Mako Fully Synthetic (0W-40)",
    }[type] || ""
  );
};

export const prettifyLubeServSite = (lubeServSite) => {
  return (
    {
      [LubeServSite.LubeServAnabuImusCavite]: "LubeServ Anabu Imus Cavite",
      [LubeServSite.LubeServTelabastaganPampanga]: "LubeServ Telabastagan Pampanga",
      [LubeServSite.LubeServCamarinCaloocan]: "Lubeserv Camarin Caloocan - NCR",
      [LubeServSite.LubeServCarmonaCavite]: "Lubeserv Bancal Carmona - Cavite",
      [LubeServSite.LubeServShawBlvd]: "Lubeserv Shaw Boulevard",
    }[lubeServSite] || ""
  );
};

export const prettifyPaymentMethod = (paymentMethod) => {
  return (
    {
      [PaymentMethod.FuelCode]: locale.fuelCodePayment,
      [PaymentMethod.FleetCard]: locale.fleetCardPayment,
      [PaymentMethod.FleetCardManualPO]: locale.fleetCardManualPo,
      [PaymentMethod.PLBLocqpayManualPOCredit]: locale.cardLessPO,
    }[paymentMethod] || ""
  );
};

export const prettifyPaymentStatus = (paymentStatus) => {
  return (
    {
      [PaymentStatus.Success]: locale.success,
      [PaymentStatus.Voided]: locale.voided,
    }[paymentStatus] || ""
  );
};

export const prettifyVipType = (vipType) => {
  return (
    {
      [VipType.Consumer]: "VIP Consumer",
      [VipType.Rides]: "VIP Rides",
      [VipType.Biz]: "VIP Biz",
      [VipType.Public]: "VIP Public",
    }[vipType] || "VIP Consumer"
  );
};

export const prettifyFleetStatus = (status) => {
  return (
    {
      [FleetStatus.Active]: locale.active,
      [FleetStatus.InActive]: locale.inactive,
    }[status] || ""
  );
};

export const prettifyBilledStatus = (status) => {
  return (
    {
      [BilledStatus.Billed]: locale.unpaid,
      [BilledStatus.Paid]: locale.paid,
    }[status] || ""
  );
};
